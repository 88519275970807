import type {ReactElement, ReactNode} from 'react'
import {Icon} from './common/icon'

interface BlogHeadingProps {
	className: string
	title: ReactNode
	authorImage?: ReactNode
	postedByText: ReactNode
	shareBlock: ReactNode
}

export const BlogHeading = ({
	className = '',
	title = null,
	authorImage = null,
	postedByText = null,
	shareBlock = null,
}: BlogHeadingProps): ReactElement => {
	const shouldRenderAuthorBlock = authorImage || postedByText
	const alignClass = shouldRenderAuthorBlock
		? 'justify-between'
		: 'justify-end'

	return (
		<div className={className}>
			{title ? (
				<h1 className="mb-3 text-3xl md:text-4xl">{title}</h1>
			) : null}
			<div className={`${alignClass} mb-6 items-center md:flex`}>
				{shouldRenderAuthorBlock ? (
					<div className="flex items-center">
						{authorImage ? (
							<Icon className="mr-4" noBorder noShadow size="sm">
								{authorImage as ReactElement}
							</Icon>
						) : null}
						{postedByText ? (
							<p className="text-sm">{postedByText}</p>
						) : null}
					</div>
				) : null}
				{shareBlock ? (
					<div className="ml-6 text-right">{shareBlock}</div>
				) : null}
			</div>
		</div>
	)
}
