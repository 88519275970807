import {EllipseButton} from '@/_new-code/products/flexible-web-toolkit/components/button/ellipse-button'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'

interface EmailSubscriptionFormProps {
	title: string
	placeholder: string
	buttonText: string
}

export const EmailSubscriptionForm = (
	props: EmailSubscriptionFormProps
): JSX.Element => (
	<div>
		<p className="mt-4 pb-3 font-bold leading-tight">{props.title}</p>
		<form action="" className="relative">
			<label htmlFor="sign-up">
				<span className="visuallyhidden">{props.title}</span>
				<input
					className="mb-0 border border-gray-500"
					id="sign-up"
					pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
					placeholder={props.placeholder}
					type="email"
				/>
			</label>
			<EllipseButton
				as="button"
				className="absolute inset-y-0 right-0 my-auto mr-2"
				icon={ButtonIcons.Plane}
				iconSize="w-6 h-6"
				type="submit"
			>
				{props.buttonText}
			</EllipseButton>
		</form>
	</div>
)
