import type {PropsWithChildren, ReactElement} from 'react'
import {cloneElement, isValidElement, useEffect, useState} from 'react'
import {UseTheme} from './theme-context'
import {getDataAttributes} from './utils'

type Target = '_blank' | '_self' | '_parent' | '_top' | ''
export type Variant =
	| 'primary'
	| 'secondary'
	| 'danger'
	| 'inverted'
	| 'tertiary'
	| 'pet_primary_green'

interface ThemeContextType {
	colorScheme: string
	getLogo: (width?: number, height?: number) => JSX.Element
	getSubTheme: () => {
		background: string
		textcolor: string
	}
}
interface EllipseButtonProps {
	as?: 'a' | 'button'
	children?:
		| string
		| ReactElement<
				PropsWithChildren<{
					className?: string
					dangerouslySetInnerHTML?: {__html: string}
				}>
		  >
		| ReactElement<
				PropsWithChildren<{
					className?: string
					dangerouslySetInnerHTML?: {__html: string}
				}>
		  >[]
	className?: string
	disabled?: boolean
	iconSize?: string
	iconText?: string
	href?: string

	icon?:
		| ReactElement<{className?: string}>
		| {class: string; icon: ReactElement<{className?: string}>}
		| null
	noIconAnimation?: boolean
	onClick?: React.MouseEventHandler<HTMLAnchorElement> &
		React.MouseEventHandler<HTMLButtonElement>
	target?: Target
	title?: string
	type?: 'button' | 'submit' | 'reset'
	variant?: Variant
	size?: string
	tabIndex?: number
	noVariant?: boolean
}

export const EllipseButton = (props: EllipseButtonProps): JSX.Element => {
	const [subTheme, setSubTheme] = useState<string>()

	const {getSubTheme} = UseTheme() as ThemeContextType

	useEffect(() => {
		setSubTheme(getSubTheme().background)
	}, [getSubTheme])

	const {
		as: Tag = 'a',
		children,
		className = '',
		disabled = false,
		href,
		iconSize,
		iconText = '',
		noIconAnimation = false,
		onClick,
		size = 'md',
		tabIndex = '',
		target,
		title = '',
		type = 'button',
		variant = 'primary',
	} = props
	const dataProps = getDataAttributes(
		props as unknown as Record<string, unknown>
	)
	let {icon} = props

	let iconAnimClass = ''
	if (icon && typeof icon === 'object' && 'icon' in icon) {
		iconAnimClass = noIconAnimation ? '' : `btn-${icon.class}`
		icon = icon.icon
	}

	const classes: Record<Variant, string> = {
		danger: 'bg-red-500 hover:bg-red-600',
		inverted:
			'bg-none border-2 text-primary border-primary hover:bg-primary hover:text-white',
		primary:
			'bg-theme-main text-white hover:bg-theme-highlight focus:bg-secondary',
		secondary:
			'text-black border-2 border-black hover:bg-black hover:text-white ',
		tertiary: 'bg-white text-theme-main shadow-sm',
		pet_primary_green:
			'bg-green-600 hover:bg-green-600 border-2 border-green-600 text-white',
	}

	const sizes = {
		lg: 'w-24 h-24',
		md: 'w-12 h-12',
		sm: 'w-8 h-8',
	}
	const sizeClass = sizes[size as keyof typeof sizes] || size || sizes.md
	const iconSizes = {
		lg: 'w-12 h-12',
		md: 'w-4 h-4',
	}
	const iconClass =
		iconSize || iconSizes[size as keyof typeof iconSizes] || iconSizes.md
	let newIcon

	if (icon) {
		newIcon = cloneElement(icon, {
			className: `${
				icon.props.className || ''
			} block fill-current overflow-visible ${iconClass}`,
			...(icon.type === 'svg' && {focusable: false}),
			key: icon.key,
		})
	}

	let tagAttributes: {
		href?: string
		target?: Target
		type?: 'button' | 'submit' | 'reset'
		disabled?: boolean
		tabIndex?: number
	} = {
		href,
	}

	if (target) {
		tagAttributes.target = target
	}

	if (tabIndex) {
		tagAttributes.tabIndex = tabIndex
	}

	if (Tag === 'button') {
		tagAttributes = {disabled, type}
	}

	// if data-btn-id is not set on each button, using the hyphenated button text as data-btn-id for tracking purpose
	if (
		children &&
		(dataProps['data-btn-id'] === undefined ||
			dataProps['data-btn-id'] === '')
	) {
		let dataBtnIdFromText = '' // Assuming children is just text and overwrite below if not
		if (Array.isArray(children)) {
			// children is an array of text and element e.g: "lorem <span>ipsum</span>"
			let tempText = ''
			tempText = children
				.map((c) => {
					return !c.type ? c : c.props.children
				})
				.join('')
			dataBtnIdFromText = tempText.replace(/,/g, '-')
		} else if (isValidElement(children)) {
			const childText = children.props.children
			dataBtnIdFromText = typeof childText === 'string' ? childText : ''
		} else if (typeof children === 'string') {
			// children is plain text
			dataBtnIdFromText = children
		}

		dataProps['data-btn-id'] = dataBtnIdFromText.split(' ').join('-')
	}

	const getAriaLabel = (
		labelTitle?: string,
		labelContent?: EllipseButtonProps['children']
	): string | undefined => {
		if (labelTitle) {
			return labelTitle
		}

		if (typeof labelContent === 'string') {
			return labelContent
		}

		if (Array.isArray(labelContent)) {
			const joinedContent = labelContent
				.map((child) => {
					if (typeof child === 'string') {
						return child
					} else if (
						isValidElement(child) &&
						typeof child.props.children === 'string'
					) {
						return child.props.children
					}
					return ''
				})
				.join(' ')
				.trim()

			return joinedContent || undefined
		}

		if (
			isValidElement(labelContent) &&
			typeof labelContent.props.children === 'string'
		) {
			return labelContent.props.children
		}

		return undefined
	}

	return (
		<Tag
			{...tagAttributes}
			{...dataProps}
			aria-label={getAriaLabel(title, children)}
			className={`btn btn-ellipse ${iconAnimClass} ${
				subTheme || classes[variant]
			} ${sizeClass} inline-block cursor-pointer overflow-hidden rounded-full font-bold text-white
        focus:bg-secondary ${className}
        ${disabled && Tag === 'a' ? 'disabled' : ''}`}
			onClick={onClick}
			title={title}
		>
			<span className="flex h-full w-full flex-col items-center justify-center">
				{newIcon}
				{iconText ? (
					<span className="text-sm" style={{textAlign: 'center'}}>
						{iconText}
					</span>
				) : null}
			</span>
		</Tag>
	)
}
