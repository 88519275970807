import React, {useState} from 'react'
import Image from 'next/future/image'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import {loadImageFromKontentAI} from '@/imageLoader'
import {TextButton} from './button/text-button'
import {EllipseButton} from './button/ellipse-button'

interface Rep {
	name: string
	jobTitle: string
	phoneNumber: string
	email: string
	thumbnailUrl: string | undefined
}

interface FindYourRepProps {
	title: string
	desc: string
	reps: Rep[]
	searchLabel?: string
	chooseFromRepsLabel?: string
	emailNowText?: string
	yourRepsText?: string
	className?: string
}

export const FindYourRep = ({
	className = '',
	title = '',
	desc = '',
	reps = [],
	searchLabel = 'Search',
	chooseFromRepsLabel = 'Choose from our reps',
	emailNowText = 'Email me now',
	yourRepsText = 'Your representative(s)',
}: FindYourRepProps): JSX.Element => {
	const [searchResults, setSearchResults] = useState<Rep[]>([])
	// This is for search input
	const [searchTerm, setSearchTerm] = useState('')
	// This is for dropdown
	const [selectedRepOption, setSelectedRepOption] = useState('')

	const filterReps = (keyword: string): void => {
		if (!keyword.length) {
			return
		}
		const filteredReps = reps.filter((option) => {
			return option.name.toLowerCase().includes(keyword.toLowerCase())
		})
		setSearchResults(filteredReps)
	}

	const handleSearchInput = (
		e: React.ChangeEvent<HTMLInputElement>
	): void => {
		setSearchTerm(e.target.value)
	}

	const handleSelectOption = (
		e: React.ChangeEvent<HTMLSelectElement>
	): void => {
		setSelectedRepOption(e.target.value)
		setSearchTerm('')
		filterReps(e.target.value)
	}

	const searchReps = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault()
		setSelectedRepOption('')
		filterReps(searchTerm)
	}

	const renderRepOptions = (option: Rep): JSX.Element => {
		return (
			<option key={option.name} value={option.name}>
				{option.name}
			</option>
		)
	}

	const renderSearchResult = (result: Rep, index: number): JSX.Element => {
		return (
			<div
				className={`rounded-lg border border-gray-400 bg-gray-200 p-4 text-center sm:flex sm:items-center sm:p-6 sm:text-left ${
					index > 0 ? 'mt-6' : ''
				}`}
				key={result.name}
			>
				<div className="sm:w-1/2 sm:pr-8">
					<Image
						alt={result.name}
						className="mx-auto rounded-full"
						height={300}
						loader={loadImageFromKontentAI}
						src={result.thumbnailUrl || ''}
						width={300}
					/>
				</div>
				<div className="mt-4 sm:mt-0">
					<h4 className="mb-1 text-xl">{result.name}</h4>
					<p className="mb-6">{result.jobTitle}</p>
					<p className="mb-3">
						<TextButton
							className="text-sm not-italic"
							href={`tel:${result.phoneNumber}`}
							icon={
								<svg height="21" viewBox="0 0 21 21" width="21">
									<path
										d="M20.4,14.84l-4.594-1.969a.984.984,0,0,0-1.148.283L12.624,15.64A15.2,15.2,0,0,1,5.357,8.372L7.842,6.338a.982.982,0,0,0,.283-1.148L6.157.6A.991.991,0,0,0,5.029.025L.763,1.01A.984.984,0,0,0,0,1.969,19.029,19.029,0,0,0,19.031,21a.984.984,0,0,0,.96-.763l.984-4.266A1,1,0,0,0,20.4,14.84Z"
										transform="translate(0 0)"
									/>
								</svg>
							}
						>
							<span>{result.phoneNumber}</span>
						</TextButton>
					</p>
					<p>
						<TextButton
							className="text-sm not-italic"
							href={`mailto:${result.email}`}
							icon={
								<svg
									height="24.456"
									viewBox="0 0 22.709 24.456"
									width="22.709"
								>
									<path
										d="M25.511,11.956c-.218-.218-8.641-8.811-8.641-8.811a3,3,0,0,0-4.28.005S4.2,11.694,3.948,11.961a2.193,2.193,0,0,0-.573,1.425V24.959a1.752,1.752,0,0,0,1.747,1.747H24.337a1.752,1.752,0,0,0,1.747-1.747V13.332A2.1,2.1,0,0,0,25.511,11.956Zm-1.059,2.309-4.427,4.629a.106.106,0,0,0,0,.153l4.111,4.378a.708.708,0,0,1,0,1,.712.712,0,0,1-1,0l-8.243-8.8a.215.215,0,0,0-.317,0L6.334,24.424a.712.712,0,0,1-1,0,.708.708,0,0,1,0-1L9.44,19.042a.116.116,0,0,0,0-.153L5,14.26a1.245,1.245,0,0,1,.879-1.954H23.584A1.289,1.289,0,0,1,24.452,14.265Z"
										transform="translate(-3.375 -2.25)"
									/>
								</svg>
							}
						>
							<span>{emailNowText}</span>
						</TextButton>
					</p>
				</div>
			</div>
		)
	}

	return (
		<div
			className={`max-w-xl overflow-hidden rounded-lg border border-gray-500 bg-gray-100 p-4 shadow-secondary ${className}`}
		>
			<div className="mb-12 px-5">
				<h3 className="mb-2">{title}</h3>
				<p>{desc}</p>
			</div>
			<div className="w-full">
				<form action="" className="relative" onSubmit={searchReps}>
					<label htmlFor="search-rep">
						<span className="visuallyhidden">{searchLabel}</span>
						<input
							className="mb-0 text-black"
							id="search-rep"
							onChange={handleSearchInput}
							placeholder={searchLabel}
							type="text"
							value={searchTerm}
						/>
					</label>
					<EllipseButton
						as="button"
						className="absolute inset-y-0 right-0 my-auto mr-2"
						icon={ButtonIcons.Search}
						iconSize="w-6 h-6"
						type="submit"
					>
						{searchLabel}
					</EllipseButton>
				</form>

				<div className="relative mt-6">
					<label htmlFor="select-rep">
						<span className="visuallyhidden">
							{chooseFromRepsLabel}
						</span>
						<select
							className="appearance-none border border-gray-500"
							id="select-rep"
							onChange={handleSelectOption}
							value={selectedRepOption}
						>
							<option value="">{chooseFromRepsLabel}</option>
							<option disabled value="">
								-------------------------------------------
							</option>
							{reps.map(renderRepOptions)}
						</select>
						<svg
							height="46"
							viewBox="0 0 48 48"
							width="46"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								fill="#0067B1"
								height="48"
								rx="24"
								width="48"
							/>
							<path
								d="M7,10,18.426,21.426,29.853,10Z"
								fill="#fff"
								fillRule="evenodd"
								transform="translate(5.574 10.287)"
							/>
						</svg>
					</label>
				</div>
			</div>

			{searchResults.length > 0 && (
				<div className="mt-8">
					<p className="mb-2 px-5 italic">{yourRepsText}</p>
					<div
						className={
							searchResults.length > 1
								? 'max-h-screen overflow-y-scroll sm:h-64'
								: ''
						}
					>
						{searchResults.map(renderSearchResult)}
					</div>
				</div>
			)}
		</div>
	)
}
