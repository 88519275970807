import {Icon as CIcon} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {customBrandChoices} from '@/components/BlockMapper/utils'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {IconWithTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon-with-text'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {KeyInfoModuleColumns as ClKeyInfoModuleColumns} from '@/_new-code/products/flexible-web-toolkit/components/key-info-module-columns'

export type KeyInfoModuleColumnsContentItem = IContentItem<{
	title: Elements.TextElement
	iconsWithText: Elements.LinkedItemsElement<IconWithTextContentItem>
}>

export const KeyInfoModuleColumnsBlock: Block<
	KeyInfoModuleColumnsContentItem
> = ({block, ...context}) => {
	return (
		<div>
			{block.elements.title ? (
				<h2
					className="text-center"
					data-kontent-element-codename="title"
				>
					{block.elements.title}
				</h2>
			) : null}
			<ClKeyInfoModuleColumns className="bg-white py-6 text-black lg:px-14 lg:py-12">
				{block.elements.iconsWithText.map((icon) => (
					<div
						className="flex"
						data-kontent-item-id={icon.system.id}
						key={icon.system.id}
						role="listitem"
					>
						<CIcon
							brand={
								icon.elements.brand[0]?.codename &&
								customBrandChoices.includes(
									icon.elements.brand[0]?.codename
								)
									? 'none'
									: icon.elements.brand[0]?.codename || 'none'
							}
							noBorder
							size={icon.elements.iconSize[0]?.codename || 'xs'}
						>
							{icon.elements.icon[0] ? (
								<div
									className="relative h-full w-full"
									data-kontent-element-codename="icon"
								>
									<Image
										alt={
											icon.elements.icon[0].description ??
											undefined
										}
										data-kontent-element-codename="icon"
										fill
										loader={loadImageFromKontentAI}
										src={icon.elements.icon[0].url}
									/>
								</div>
							) : null}
						</CIcon>

						<div className="ml-5 w-full">
							{icon.elements.title ? (
								<strong
									className="mb-2 inline-block"
									data-kontent-element-codename="title"
								>
									{icon.elements.title}
								</strong>
							) : null}
							<RichTextRenderer
								element={icon.elements.description}
								{...context}
							/>
						</div>
					</div>
				))}
			</ClKeyInfoModuleColumns>
		</div>
	)
}
