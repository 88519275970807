import {Pagination} from '@elanco/component-library-v2'
import {useInfiniteQuery} from 'react-query'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useContext, useEffect, useState} from 'react'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import {SkeletonLoader} from '@/_new-code/products/flexible-web-toolkit/components/skeleton-loader'
import {pushToDataLayer} from '@/utils/analytics'
import type {
	Block,
	TaxonomySpeciesContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {fetchListerItems} from '@/fetchers/fetchListerItems'
import type {ListerItemsQueryKey} from '@/_new-code/products/flexible-web-toolkit/blocks/module-coupon-lister/types'
import {
	EnhancedProductDirectoryCardBlock,
	type EnhancedProductDirectoryCardContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/enhanced-product-card'
import {GlobalContext} from '@/components/BlockMapper/GlobalClientState'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {textChecker} from '@/utils/rich-text-helper'
import {ListerSorting} from '../lister-sorting'
import {dropDownItems, sortItems} from '../lister-helper'
import type {ListerSortingContentItem, SortingCriterion} from '../lister-model'

export type ProductDirectoryListerContentItem = IContentItem<{
	initialItemsNumber: Elements.NumberElement
	subsequentItemsNumber: Elements.NumberElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	usePagination: Elements.MultipleChoiceElement
	paginationString: Elements.TextElement
	buttonText: Elements.TextElement
	nextButtonText: Elements.TextElement
	prevButtonText: Elements.TextElement
	itemsOrderingOptions: Elements.MultipleChoiceElement
	productDirectoryListerSorting: Elements.LinkedItemsElement<ListerSortingContentItem>
}>

export const ProductDirectoryListerBlock: Block<
	ProductDirectoryListerContentItem
> = ({block, page, globalConfig, ...context}) => {
	const router = useRouter()
	const gs = useContext(GlobalContext)
	const authState = useAuth()

	const {page: currentPage} = router.query

	const [productCards, setProductCards] = useState<
		Tersed<EnhancedProductDirectoryCardContentItem>[]
	>([])

	const [sortingCriterion, setSortingCriterion] = useState<
		SortingCriterion | undefined
	>()

	const {buttonText} = block.elements

	const nextButtonText =
		'nextButtonText' in block.elements && block.elements.nextButtonText
			? block.elements.nextButtonText
			: 'Next'

	const prevButtonText =
		'prevButtonText' in block.elements && block.elements.prevButtonText
			? block.elements.prevButtonText
			: 'Prev'

	const subsequentArticlesNumber = block.elements.subsequentItemsNumber ?? 6

	const initialItemsNumber = block.elements.initialItemsNumber ?? 6

	const [limitNumberWithSubSeq, setLimitNumberWithSubSeq] =
		useState(initialItemsNumber)

	const speciesCodename =
		'filterByTaxonomy' in page.elements
			? (
					page.elements
						.filterByTaxonomy as Tersed<TaxonomySpeciesContentItem>[]
				)[0]?.elements.species[0]?.codename
			: undefined

	const speciesFilter = [
		{
			taxonomy: 'species',
			terms: [speciesCodename],
		},
	]
		.map((item) => ({
			...item,
			terms: item.terms.filter(Boolean),
		}))
		.filter((item) => item.terms.length > 0)

	const [filters, setFilters] = useState<
		{
			taxonomy: string
			terms: string[]
		}[]
	>()

	useEffect(() => {
		const productsFilterOptions = gs?.[0].productsFilterOptions ?? {}
		const updatedFilters = Object.entries(productsFilterOptions).map(
			([taxonomy, options]) => ({
				taxonomy,
				terms: (() => {
					if ('checkboxOptions' in options) {
						return options.checkboxOptions
							.filter((opt) => opt.checked)
							.map((opt) => opt.codename)
					}
					return [options.value]
				})(),
			})
		)
		setFilters([...speciesFilter, ...updatedFilters])
		setLimitNumberWithSubSeq(initialItemsNumber)

		// eslint-disable-next-line react-hooks/exhaustive-deps -- intentional as speciesFilter is static and doesn't change after the initial render, so it's safe to omit it from the dependency array.
	}, [gs])

	const usePagination = block.elements.usePagination[0]?.codename === 'yes'

	const {isLoading, isFetching, fetchNextPage, data} = useInfiniteQuery({
		queryKey: [
			'productDirectoryLister',
			{
				codename: 'enhanced_product_directory_card',
				locale: router.locale ?? '',
				filters: JSON.stringify(filters),
				orderBy:
					block.elements.itemsOrderingOptions[0]?.codename ??
					undefined,
				hideElementCodename: 'hide_from_lister',
				skip:
					(Number(currentPage?.toString() ?? 1) - 1) *
					initialItemsNumber,
				limit: limitNumberWithSubSeq,
			},
		] as ListerItemsQueryKey,
		queryFn: (opts) =>
			fetchListerItems<Tersed<EnhancedProductDirectoryCardContentItem>>(
				opts
			),
		staleTime: 10 * (60 * 1000),
		cacheTime: 15 * (60 * 1000),
		enabled: globalConfig.elements.cdcKey
			? (authState.ready && !authState.authenticated) ||
				(authState.ready &&
					authState.authenticated &&
					Boolean(authState.userDetails.id))
			: true,
	})

	useEffect(() => {
		const productCardItems = data?.pages.flatMap((item) => item.items)
		const sortedProducts = sortItems<
			Tersed<EnhancedProductDirectoryCardContentItem>
		>(productCardItems || [], sortingCriterion)
		setProductCards(sortedProducts)
	}, [data, sortingCriterion])

	const totalCount = data?.pages[0]?.pagination.totalCount ?? 0

	function getDisplayedItemCount(): number {
		return productCards.length
	}

	function getHasNextPage(): boolean {
		const displayedItems = getDisplayedItemCount()
		return displayedItems < totalCount
	}

	const handleSortingLister = (
		e: React.ChangeEvent<HTMLSelectElement>
	): void => {
		setSortingCriterion(e.target.value as SortingCriterion)
	}

	const onPageChange = (nextPage: number): void => {
		const currentQuery = new URLSearchParams(
			router.asPath.split('?')[1] || ''
		)
		currentQuery.set('page', nextPage.toString())

		void router.push({
			pathname: `${router.locale ?? ''}${router.asPath.split('?')[0]}`,
			query: currentQuery.toString(),
		})
	}

	return (
		<div>
			{isFetching || !data ? (
				<div className="mx-auto my-2 grid max-w-screen-xl grid-cols-1 gap-10 px-3 sm:grid-cols-2 sm:px-0 md:grid-cols-3">
					<SkeletonLoader
						amount={
							!data
								? initialItemsNumber
								: subsequentArticlesNumber
						}
					/>
				</div>
			) : null}
			{!isLoading && data?.pages[0] ? (
				<div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between">
					<p
						className="mb-4 px-4 text-center font-bold sm:text-left"
						data-kontent-element-codename="pagination_string"
					>
						{block.elements.paginationString
							.replaceAll(
								'{{X}}',
								getDisplayedItemCount().toString()
							)
							.replaceAll('{{Y}}', totalCount.toString())}
					</p>
					{block.elements.productDirectoryListerSorting[0] ? (
						<ListerSorting
							className="w-full px-4 sm:w-auto"
							codename={
								block.elements.productDirectoryListerSorting[0]
									?.system.codename
							}
							dropDownData={dropDownItems(
								block.elements.productDirectoryListerSorting[0]
							)}
							id={
								block.elements.productDirectoryListerSorting[0]
									?.system.id || 'sorting_product_listers'
							}
							label={textChecker(
								block.elements.productDirectoryListerSorting[0]
									?.elements.defaultSortingText.value || '',
								'Sort By'
							)}
							onChange={handleSortingLister}
						/>
					) : null}
				</div>
			) : null}
			{!isLoading && data ? (
				<div className="mx-auto flex max-w-screen-xl flex-wrap justify-center">
					{productCards
						.filter(authState.checkUserRoleForBlock)
						.map((item) => (
							<div
								className="my-2 flex w-full flex-wrap justify-center sm:w-1/2 md:w-1/3"
								key={item.system.id}
							>
								<EnhancedProductDirectoryCardBlock
									block={item}
									globalConfig={globalConfig}
									page={page}
									{...context}
								/>
							</div>
						))}
				</div>
			) : null}
			{isFetching ? (
				<div className="mx-auto my-2 grid max-w-screen-xl grid-cols-1 gap-10 px-3 sm:grid-cols-2 sm:px-0 md:grid-cols-3">
					<SkeletonLoader
						amount={
							!data
								? initialItemsNumber
								: subsequentArticlesNumber
						}
					/>
				</div>
			) : null}
			{usePagination ? (
				<Pagination
					activeIndex={currentPage ?? 1}
					amountOfPages={Math.ceil(totalCount / initialItemsNumber)}
					nextButtonText={<span>{nextButtonText}</span>}
					onPageChange={onPageChange}
					pagesToShow={3}
					prevButtonText={<span>{prevButtonText}</span>}
					showLastPage
				/>
			) : (
				buttonText &&
				getHasNextPage() && (
					<div
						className="my-10 text-center"
						data-kontent-element-codename="button_text"
					>
						<Button
							onClick={async () => {
								setLimitNumberWithSubSeq(
									limitNumberWithSubSeq +
										subsequentArticlesNumber
								)
								await fetchNextPage()
								pushToDataLayer({
									event: 'cta_click',
									cta_name: buttonText,
									cta_category: 'button',
								})
							}}
							type="button"
						>
							{buttonText}
						</Button>
					</div>
				)
			)}
		</div>
	)
}
