import {Image} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type VetRebateNoCrmIdFoundContentItem = IContentItem<{
	formTitlee: Elements.TextElement
	formSubTitle: Elements.TextElement
	linkToHomepage: Elements.TextElement
	linkToSupportPage: Elements.TextElement
	textlinkToSupportPage: Elements.TextElement
	image: Elements.AssetsElement
}>

export const VetRebateNoCrmIdFoundBlock: Block<
	VetRebateNoCrmIdFoundContentItem
> = ({block}) => {
	const {locale = ''} = useRouter()
	return (
		<div className="flex h-screen items-center justify-center ">
			<div className="ml-4 mr-4 w-full pb-52 text-center lg:w-[60%]">
				<Image
					alt="danger_image"
					className="relative mx-auto my-8 h-1/3 w-1/3 lg:top-8 lg:h-[12%] lg:w-[12%]"
					height={90}
					src={block.elements.image[0]?.url}
					width={20}
				/>
				<div>
					<h1>{block.elements.formTitlee}</h1>
				</div>
				<div>
					<p className="mt-2 text-gray-600">
						{block.elements.formSubTitle}
					</p>
				</div>
				<a href={`/${locale}`}>
					<Button
						className="mt-4 rounded-[0.3rem] pl-[3.25rem] pr-[3.25rem]"
						variant="primary"
					>
						{block.elements.linkToHomepage}
					</Button>
				</a>
				<div className="pt-5">
					<a
						className="mr-6 mt-auto text-primary"
						href={block.elements.textlinkToSupportPage}
					>
						{block.elements.linkToSupportPage}
					</a>
				</div>
			</div>
		</div>
	)
}
