/*Import Button from '@/_new-code/products/flexible-web-toolkit/components/button/button' Once MediaCard is imported*/
import {MediaCard as ClMediaCard, Button} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Image from 'next/future/image'
import {useTrustArcCookieValue} from '@/utils/analytics'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'

export type MediaCardContentItem = IContentItem<{
	title: Elements.TextElement
	image: Elements.AssetsElement
	content: Elements.RichTextElement
	videoid: Elements.TextElement
	videotype: Elements.MultipleChoiceElement
	buttontext: Elements.TextElement
	cookieFallbackImage: Elements.AssetsElement
}>

export const MediaCardBlock: Block<MediaCardContentItem> = ({
	block,
	...context
}) => {
	const trustArcCookie = useTrustArcCookieValue()
	const hideVideo =
		trustArcCookie === '1:' || trustArcCookie === '0:' ? 'hide' : ''

	return (
		<ClMediaCard
			className="h-full w-84 flex-none"
			data-kontent-element-codename="media_card"
			hasPlayVideoButton
			title={
				<span data-kontent-element-codename="title">
					{block.elements.title}
				</span>
			}
			videoId={hideVideo === 'hide' ? '' : block.elements.videoid}
			videoPosterFrameUrl={
				hideVideo === 'hide' ? '' : block.elements.image[0]?.url
			}
			videoType={block.elements.videotype[0]?.codename}
		>
			{hideVideo === 'hide' &&
				(block.elements.cookieFallbackImage[0] ? (
					<Image
						alt={
							block.elements.cookieFallbackImage[0].description ??
							undefined
						}
						className="mb-4 h-48 w-full flex-shrink-0 object-contain"
						fill
						src={block.elements.cookieFallbackImage[0].url}
					/>
				) : (
					<div className="order-first mb-4 h-[192px] w-full bg-blue-900" />
				))}
			<RichTextRenderer
				data-kontent-element-codename="text"
				element={block.elements.content}
				{...context}
			/>
			{hideVideo === 'hide' ? (
				''
			) : (
				<Button className="w-full">
					{block.elements.buttontext || 'Play video'}
				</Button>
			)}
		</ClMediaCard>
	)
}
