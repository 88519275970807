import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Link from 'next/link'
import {CardTabs} from '@elanco/component-library-v2'
import {Card} from '@/_new-code/products/flexible-web-toolkit/components/card'
import {CardImage} from '@/_new-code/products/flexible-web-toolkit/components/card/card-image'
import {TextButton} from '@/_new-code/products/flexible-web-toolkit/components/button/text-button'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {Tab} from '@/_new-code/products/flexible-web-toolkit/components/tabs/tab'
import {RichTextRenderer} from '../../components/rich-text-renderer'

type ProductDirectoryTabContentItem = IContentItem<{
	content: Elements.RichTextElement
	buttonText: Elements.TextElement
	buttonLink: Elements.TextElement
	tabTitle: Elements.TextElement
}>

export type ProductDirectoryCardContentItem = IContentItem<{
	image: Elements.AssetsElement
	title: Elements.TextElement
	tabs: Elements.LinkedItemsElement<ProductDirectoryTabContentItem>
	whereToBuyUrl: Elements.TextElement
	productLabelUrl: Elements.TextElement
}>

export const ProductDirectoryCardBlock: Block<
	ProductDirectoryCardContentItem
> = ({block, ...context}) => {
	return (
		<div data-kontent-element-codename={block.system.codename}>
			<Card
				className="relative m-8"
				title={
					<span data-kontent-element-codename="title">
						{block.elements.title}
					</span>
				}
			>
				{block.elements.image[0] ? (
					<CardImage
						alt={block.elements.image[0].description}
						src={block.elements.image[0].url}
					/>
				) : null}
				<CardTabs name={block.system.codename}>
					{block.elements.tabs.map((tab) => (
						<Tab
							hideCloseBtn
							key={tab.system.codename}
							tabPaddingClasses="p-4"
							title={tab.elements.tabTitle}
						>
							<div data-kontent-item-id={tab.system.id}>
								<RichTextRenderer
									element={tab.elements.content}
									{...context}
								/>
								{tab.elements.buttonLink ? (
									<Link
										href={tab.elements.buttonLink}
										passHref
									>
										<Button
											className="w-full"
											data-kontent-element-codename="button_text"
										>
											{tab.elements.buttonText}
										</Button>
									</Link>
								) : null}
							</div>
						</Tab>
					))}
				</CardTabs>
				<div className="mt-6 flex items-center justify-between">
					<Button
						className="mr-2 flex-shrink-0 text-sm"
						href={block.elements.whereToBuyUrl}
					>
						Where to Buy
					</Button>
					<TextButton
						className="ml-2 text-center"
						href={block.elements.productLabelUrl}
						// TODO: Figure out where this value came from before because its not in the content item
						// target={
						// 	block.open_in_new_tab?.boolean ? '_blank' : '_self'
						// }
					>
						<span>Product Label</span>
					</TextButton>
				</div>
			</Card>
		</div>
	)
}
