import type {ChangeEvent} from 'react'
import {useState} from 'react'
import Fuse from 'fuse.js'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {EllipseButton} from '@/_new-code/products/flexible-web-toolkit/components/button/ellipse-button'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {CtaButtonBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {defaultMargins} from '@/_new-code/products/flexible-web-toolkit/styles'
import {Card} from '@/_new-code/products/flexible-web-toolkit/components/card'
import {CardImage} from '@/_new-code/products/flexible-web-toolkit/components/card/card-image'
import {CardFooter} from '@/_new-code/products/flexible-web-toolkit/components/card/card-footer'

type FilterBlockCardContentItem = IContentItem<{
	topic: Elements.TextElement
	filterableCardButtons: Elements.LinkedItemsElement<CtaButtonContentItem>
	summary: Elements.TextElement
	cardImage: Elements.AssetsElement
	authorName: Elements.TextElement
	region: Elements.TextElement
}>

export type FilterBlockContentItem = IContentItem<{
	filterBlockCards: Elements.LinkedItemsElement<FilterBlockCardContentItem>
}>

/**
 * Function to generate a unique set of dropdown options for each of the
 * filterable options available on filterBlockCards.
 *
 * @param filterBlockCards - The full list of unfiltered filterBlockCards
 * @returns An object containing the filter options for each dropdown filter
 */
function generateFilterOptions(
	filterBlockCards: Tersed<FilterBlockCardContentItem>[]
): {
	topics: Set<string>
	regions: Set<string>
	authors: Set<string>
} {
	const topicFilterOptions = new Set<string>(
		filterBlockCards.map((card) => card.elements.topic)
	)
	const regionFilterOptions = new Set<string>(
		filterBlockCards.map((card) => card.elements.region)
	)
	const authorNameFilterOptions = new Set<string>(
		filterBlockCards.map((card) => card.elements.authorName)
	)

	return {
		topics: topicFilterOptions,
		regions: regionFilterOptions,
		authors: authorNameFilterOptions,
	}
}

export const FilterBlockBlock: Block<FilterBlockContentItem> = ({
	block,
	...context
}) => {
	const fuse = new Fuse(block.elements.filterBlockCards, {
		threshold: 0,
		keys: ['authorName', 'summary', 'topic'],
	})
	const {authors, regions, topics} = generateFilterOptions(
		block.elements.filterBlockCards
	)
	const [results, setResults] = useState(block.elements.filterBlockCards)
	const [selectedAuthor, setSelectedAuthor] = useState('')
	const [selectedRegion, setSelectedRegion] = useState('')
	const [selectedTopic, setSelectedTopic] = useState('')
	const [searchTerm, setSearchTerm] = useState('')

	function filterResults(): void {
		let filteredResults = block.elements.filterBlockCards

		if (searchTerm) {
			const searchResults = fuse.search(searchTerm)
			filteredResults = searchResults.map((result) => result.item)
		}

		if (selectedAuthor) {
			filteredResults = results.filter(
				(card) => selectedAuthor === card.elements.authorName
			)
		}

		if (selectedRegion) {
			filteredResults = results.filter(
				(card) => selectedRegion === card.elements.region
			)
		}

		if (selectedTopic) {
			filteredResults = results.filter(
				(card) => selectedTopic === card.elements.topic
			)
		}

		setResults(filteredResults)
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>): void {
		setSearchTerm(event.target.value)
	}

	function handleClick(): void {
		filterResults()
	}

	function handleReset(): void {
		setResults(block.elements.filterBlockCards)
		setSearchTerm('')
		setSelectedAuthor('')
		setSelectedRegion('')
		setSelectedTopic('')
	}

	return (
		<div className={`${defaultMargins} container-wide`}>
			<div className="flex flex-col">
				<div className="mx-8 flex flex-col bg-white md:flex-row lg:flex-row">
					<DropdownFilter
						handleChange={(event) => {
							setSelectedAuthor(event.target.value)
							filterResults()
						}}
						name="Author"
						options={authors}
						selectedOption={selectedAuthor}
					/>
					<DropdownFilter
						handleChange={(event) => {
							setSelectedRegion(event.target.value)
							filterResults()
						}}
						name="Region"
						options={regions}
						selectedOption={selectedRegion}
					/>
					<DropdownFilter
						handleChange={(event) => {
							setSelectedTopic(event.target.value)
							filterResults()
						}}
						name="Topic"
						options={topics}
						selectedOption={selectedTopic}
					/>
				</div>

				<div className="my-4 flex flex-col items-center md:flex-row lg:flex-row">
					<div className="mx-10 flex h-full items-center">
						<input
							onChange={handleChange}
							placeholder="Search"
							value={searchTerm}
						/>
						<EllipseButton
							as="button"
							className="ml-2"
							icon={ButtonIcons.Search}
							iconSize="w-5 h-5"
							onClick={handleClick}
							title="search"
							variant="primary"
						>
							Search
						</EllipseButton>
					</div>
					<div>
						<Button
							className="mt-2 md:ml-2 lg:ml-2"
							icon={ButtonIcons.Load}
							onClick={handleReset}
						>
							Reset
						</Button>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
				{results.map((card) => (
					<div className="m-10" key={card.system.id}>
						<Card className="h-full" title={card.elements.topic}>
							<p>{card.elements.summary}</p>
							<CardImage
								alt=""
								src={card.elements.cardImage[0]?.url ?? ''}
							/>
							<CardFooter>
								<>
									{card.elements.filterableCardButtons.map(
										(button) => (
											<CtaButtonBlock
												block={button}
												className="m-auto mr-6 mt-6"
												key={button.system.id}
												{...context}
											/>
										)
									)}
								</>
							</CardFooter>
						</Card>
					</div>
				))}
			</div>
		</div>
	)
}

interface DropdownFilterProps<T> {
	name: string
	options: Set<T>
	selectedOption: '' | T
	handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

function DropdownFilter<T extends string>(
	props: DropdownFilterProps<T>
): JSX.Element {
	return (
		<select
			className="mx-2 my-2 lg:w-1/4"
			onChange={props.handleChange}
			value={props.selectedOption}
		>
			<option value="">Select {props.name}</option>
			{[...props.options].map((option) => (
				<option key={option} value={option}>
					{option}
				</option>
			))}
		</select>
	)
}
