import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {TextButton} from '@/_new-code/products/flexible-web-toolkit/components/button/text-button'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import {convertDateToLocaleDatePicker} from '@/utils/YPAY/commonFunctions'
import Form from '@/components/Form'
import LoadingState from '@/components/Form/LoadingState'
import type {PetFormData} from '@/fetchers/fetchPetProfiles'
import Alert from '@/components/Alert'
import type {Pet} from '@/models/consumerEngagement/models'
import {pushToDataLayer} from '@/utils/analytics'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {ShelterContentItem} from '../adoptwizard'
import {usePetCreateForm} from './hooks'

export type ModulePetCreateFormContentItem = IContentItem<{
	profileCreationHeading: Elements.TextElement
	profileCreationDescription1: Elements.TextElement
	heading: Elements.TextElement
	petName: Elements.TextElement
	petSpeciesLabel: Elements.TextElement
	petSpeciesCat: Elements.TextElement
	petSpeciesDog: Elements.TextElement
	petSexLabel: Elements.TextElement
	petSexMale: Elements.TextElement
	petSexFemale: Elements.TextElement
	petDateOfBirth: Elements.TextElement
	petAdoptionDay: Elements.TextElement
	petWeight: Elements.TextElement
	petBreed: Elements.TextElement
	petAcquisition: Elements.TextElement
	breeder: Elements.TextElement
	petStore: Elements.TextElement
	shelter: Elements.TextElement
	friendFamily: Elements.TextElement
	rescued: Elements.TextElement
	other: Elements.TextElement
	shelterZipcode: Elements.TextElement
	shelterZipcodeDescription: Elements.TextElement
	searchSheltersButtonText: Elements.TextElement
	sheltersLabel: Elements.TextElement
	sheltersPlaceholder: Elements.TextElement
	clinicZipcode: Elements.TextElement
	clinicZipcodeDescription: Elements.TextElement
	searchClinicsButtonText: Elements.TextElement
	searchClinicsLabel: Elements.TextElement
	vetClinicsPlaceholder: Elements.TextElement
	urlRedirect: Elements.TextElement
	nextButtonText: Elements.TextElement
	submitButtonText: Elements.TextElement
	addExtraPetButtonText: Elements.TextElement
	duplicatePetNameErrorMessage: Elements.TextElement
	formGenericErrorMessage: Elements.TextElement
}>

type TBlock = Block<ModulePetCreateFormContentItem>
type OriginalParameters = Parameters<TBlock>
type OriginalReturnType = ReturnType<TBlock>
type ExtendedParameters = OriginalParameters[0] & {
	data?: Pet
	onSubmit: (
		formData: PetFormData,
		rawData: Pet,
		addExtraPet: boolean
	) => void
	shelterData?: Tersed<ShelterContentItem>[]
	isEdit?: boolean
	multiplePetCreation?: boolean
	hideHeading?: boolean
}
type PetCreateForm = (params: ExtendedParameters) => OriginalReturnType

export const ModulePetCreateFormBlock: PetCreateForm = ({
	block,
	data,
	onSubmit,
	hideHeading,
	isEdit = false,
	shelterData = [],
	multiplePetCreation = false,
	globalConfig,
}) => {
	const {
		submitForm,
		maxDate,
		minDate,
		minAdoptionDate,
		setMinAdoptionDate,
		breeds,
		searchClinics,
		searchShelters,
		setSelectedSpecies,
		setClinicZipCode,
		clinics,
		showVetClinic,
		showShelterSearch,
		onAcquisitionTypeChange,
		showPetShelter,
		setShelterZipCode,
		shelters,
		isLoading,
		isSubmitting,
		onShelterChange,
		createAdditionalPet,
		isLoadingVetClinics,
		isLoadingShelters,
		formFieldValue,
		isError,
		isValidPetName,
		isApiError,
	} = usePetCreateForm(
		block.elements.shelter,
		onSubmit,
		globalConfig,
		shelterData,
		data
	)

	if (isApiError) {
		return (
			<div>
				<Alert
					message={globalConfig.elements.genericApiError}
					type="error"
				/>
			</div>
		)
	}

	return (
		<div className="relative flex flex-col items-center justify-center">
			<LoadingState
				isLoading={
					isLoading ||
					isSubmitting ||
					isLoadingVetClinics ||
					isLoadingShelters
				}
				theme="green"
			/>
			<Form
				action=""
				ajaxSubmission
				className="w-full"
				onSubmit={(formData: PetFormData) => {
					void submitForm(formData)
				}}
				setFormField={formFieldValue}
			>
				{!hideHeading && (
					<h3 className="mb-6 text-center font-semibold">
						{block.elements.heading}
					</h3>
				)}
				<Form.InputField
					defaultValue={data?.name}
					invalidErrorMsg={
						block.elements.duplicatePetNameErrorMessage
					}
					label={block.elements.petName}
					max={30}
					name="petName"
					onValidate={(value: string) => {
						if (data?.name === value) return true
						return isValidPetName(value)
					}}
					type="text"
				/>
				<div className="flex flex-col gap-3 md:flex-row">
					<Form.ChoiceGroup
						className="flex flex-col"
						groupClassName="flex flex-1 flex-col"
						label={block.elements.petSpeciesLabel}
						name="petSpecies"
					>
						{/* Only dog shelters */}
						<Form.ChoiceField
							checked={data?.species === 'cat'}
							className="md:w-1/2"
							disabled={Boolean(shelterData.length)}
							id="cat"
							isGroup
							label={block.elements.petSpeciesCat}
							name="petSpecies"
							onChange={(value) => {
								setSelectedSpecies(value)
							}}
							type="radio"
							value="cat"
						/>
						<Form.ChoiceField
							checked={data?.species === 'dog'}
							className="md:w-1/2"
							id="dog"
							isGroup
							label={block.elements.petSpeciesDog}
							name="petSpecies"
							onChange={(value) => {
								setSelectedSpecies(value)
							}}
							type="radio"
							value="dog"
						/>
					</Form.ChoiceGroup>
					<Form.ChoiceGroup
						className="flex flex-col"
						groupClassName="flex flex-1 flex-col"
						label={block.elements.petSexLabel}
						name="petSex"
					>
						<Form.ChoiceField
							checked={data?.gender === 'male'}
							className="md:w-1/2"
							id="male"
							isGroup
							label="Male"
							name="petSex"
							type="radio"
							value="male"
						/>
						<Form.ChoiceField
							checked={data?.gender === 'female'}
							className="md:w-1/2"
							id="female"
							isGroup
							label="Female"
							name="petSex"
							type="radio"
							value="female"
						/>
					</Form.ChoiceGroup>
				</div>
				<div className="flex flex-col gap-3 md:flex-row">
					<Form.DateField
						className="flex flex-1"
						defaultValue={convertDateToLocaleDatePicker(
							data?.birthDate ?? ''
						)}
						invalidErrorMsg=""
						label={block.elements.petDateOfBirth}
						maxDate={maxDate}
						minDate={minDate}
						name="petDateOfBirth"
						onChange={(value) => {
							setMinAdoptionDate(value)
						}}
						requiredErrorMsg=""
					/>
					<Form.DateField
						className="flex flex-1"
						defaultValue={convertDateToLocaleDatePicker(
							data?.adoptionDate ?? ''
						)}
						invalidErrorMsg=""
						isOptional={!shelterData.length}
						label={block.elements.petAdoptionDay}
						maxDate={maxDate}
						minDate={minAdoptionDate}
						name="petAcquisitionDate"
						requiredErrorMsg=""
					/>
				</div>
				<div className="flex flex-col gap-3 md:flex-row">
					<Form.SearchableSelectField
						className="flex flex-1"
						defaultValue={data?.breed}
						label={block.elements.petBreed}
						name="breed"
						options={breeds}
					/>
					<Form.InputField
						className="flex flex-1"
						defaultValue={data?.weight.value}
						invalidErrorMsg=""
						label={block.elements.petWeight}
						max={300}
						min={0.1}
						name="petWeight"
						requiredErrorMsg=""
						type="number"
					/>
				</div>
				{shelterData.length === 0 && (
					<div className="flex flex-col">
						<Form.SelectField
							defaultValue={data?.acquisitionSource}
							label={block.elements.petAcquisition}
							name="petAcquisitionType"
							onChange={(value) => {
								onAcquisitionTypeChange(value)
							}}
							requiredErrorMsg=""
						>
							<option value="">Please select an option</option>
							<option value={block.elements.breeder}>
								{block.elements.breeder}
							</option>
							<option value={block.elements.petStore}>
								{block.elements.petStore}
							</option>
							<option value={block.elements.shelter}>
								{block.elements.shelter}
							</option>
							<option value={block.elements.friendFamily}>
								{block.elements.friendFamily}
							</option>
							<option value={block.elements.rescued}>
								{block.elements.rescued}
							</option>
							<option value={block.elements.other}>
								{block.elements.other}
							</option>
						</Form.SelectField>
					</div>
				)}
				<div className="flex flex-col gap-3 md:flex-row">
					{showShelterSearch ? (
						<Form.FieldGroup
							groupClassName="flex flex-1 flex-col"
							name="shelterFieldsForNonShelter"
						>
							{shelterData.length > 0 && (
								<>
									<Form.InputField
										className="flex flex-1"
										isOptional
										label={
											<>
												<span className="mb-1 block font-bold">
													{
														block.elements
															.shelterZipcode
													}
												</span>
												<span>
													{
														block.elements
															.shelterZipcodeDescription
													}
												</span>
											</>
										}
										name="shelterZipcode"
										onChange={(value) => {
											setShelterZipCode(value)
										}}
										type="text"
									/>

									<div className="-mt-2 mb-4 flex justify-end">
										<TextButton
											as="button"
											className="font-normal !text-pet-green"
											onClick={searchShelters}
											type="button"
										>
											<span>
												{
													block.elements
														.searchSheltersButtonText
												}
											</span>
										</TextButton>
									</div>
								</>
							)}
							{showPetShelter ? (
								<Form.SearchableSelectField
									className="w-full"
									defaultValue={data?.acquisitionDetail?.id}
									isOptional={!shelterData}
									label={block.elements.sheltersLabel}
									name="petShelter"
									onChange={(id: string) => {
										if (multiplePetCreation) {
											onShelterChange(id)
										}
									}}
									options={[
										data?.acquisitionDetail?.name
											? {
													value: data
														.acquisitionDetail.id,
													label: data
														.acquisitionDetail.name,
												}
											: {
													value: '',
													label: block.elements
														.sheltersPlaceholder,
												},
										...shelters,
									]}
								/>
							) : null}
						</Form.FieldGroup>
					) : null}
				</div>
				<Form.FieldGroup name="clinicFieldsForNonShelter">
					{shelterData.length === 0 && (
						<>
							<Form.InputField
								isOptional
								label={
									<>
										<span className="mb-1 block font-bold">
											{block.elements.clinicZipcode}
										</span>
										<span>
											{
												block.elements
													.clinicZipcodeDescription
											}
										</span>
									</>
								}
								name="clinicZipcode"
								onChange={(value) => {
									setClinicZipCode(value)
								}}
								type="text"
							/>
							<div className="-mt-2 mb-4 flex justify-end">
								<TextButton
									as="button"
									className="font-normal !text-pet-green"
									onClick={searchClinics}
									type="button"
								>
									<span>
										{block.elements.searchClinicsButtonText}
									</span>
								</TextButton>
							</div>
						</>
					)}
					{showVetClinic ? (
						<Form.SearchableSelectField
							defaultValue={data?.vetClinic?.id}
							isOptional={!shelterData.length}
							label={block.elements.searchClinicsLabel}
							name="vetClinic"
							options={[
								data?.vetClinic?.name
									? {
											value: data.vetClinic.id,
											label: data.vetClinic.name,
										}
									: {
											value: '',
											label: block.elements
												.vetClinicsPlaceholder,
										},
								...clinics,
							]}
						/>
					) : null}
				</Form.FieldGroup>
				{isError ? (
					<Alert
						message={block.elements.formGenericErrorMessage}
						type="error"
					/>
				) : null}
				<div className="flex flex-col justify-center gap-4 pt-4 text-center md:flex-row">
					<Button
						as="button"
						className="w-full flex-1 md:max-w-[245px]"
						onClick={() => {
							pushToDataLayer({
								event: 'cta_click',
								cta_name: isEdit
									? block.elements.submitButtonText
									: block.elements.nextButtonText,
								cta_category: 'CTA_Button',
							})
						}}
						type="submit"
						variant="pet_primary_green"
					>
						{isEdit
							? block.elements.submitButtonText
							: block.elements.nextButtonText}
					</Button>

					{multiplePetCreation ? (
						<Button
							as="button"
							className="w-full flex-1 md:max-w-[245px]"
							onClick={() => {
								pushToDataLayer({
									event: 'cta_click',
									cta_name:
										block.elements.addExtraPetButtonText,
									cta_category: 'CTA_Button',
								})
								createAdditionalPet()
							}}
							type="submit"
							variant="inverted_pet_primary_green"
						>
							{block.elements.addExtraPetButtonText}
						</Button>
					) : null}
				</div>
			</Form>
		</div>
	)
}
