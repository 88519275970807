import type {ReactElement} from 'react'
import {Children, cloneElement, isValidElement} from 'react'
import {Image} from '@elanco/component-library-v2'
import Link from 'next/link'

interface CardProps {
	alt: string | null
	children?: ReactElement
	className?: string
	imageUrl?: string
	src: string
	isFooterCardImage?: boolean
}

export const CardImage = ({
	alt = '',
	children: child,
	className,
	imageUrl = '',
	src,
	isFooterCardImage,
}: CardProps): ReactElement => {
	const classes = `shrink-0 ${className} ${
		isFooterCardImage ? 'w-full object-cover h-full' : 'w-full'
	}`

	if (child && isValidElement(child)) {
		return cloneElement(child as ReactElement, {
			className: 'shrink-0',
			// eslint-disable-next-line -- expecting ReactElement as a child of this
			children: Children.map((child.props as any).children, (el: ReactElement) => {
					if (el.type === 'img') {
						return cloneElement(el, {
							className: classes,
						})
					}
					return el
				}
			),
		})
	}
	return (
		<>
			{imageUrl.length ? (
				<Link href={imageUrl}>
					<Image
						alt={alt}
						className={classes}
						height={170}
						src={src}
						width={330}
					/>
				</Link>
			) : (
				<Image
					alt={alt}
					className={classes}
					height={170}
					src={src}
					width={330}
				/>
			)}
		</>
	)
}
