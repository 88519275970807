import type {PropsWithChildren, ReactElement, ReactNode} from 'react'
import {useState, useEffect, isValidElement, cloneElement} from 'react'
import {UseTheme} from './theme-context'
import {getDataAttributes} from './utils'

const classes = {
	danger: 'bg-red-500 hover:bg-red-600',
	inverted:
		'bg-none border-2 text-primary border-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white',
	inverted_pet_primary_green:
		'bg-none border-2 text-green-600 border-green-600 hover:bg-green-600 hover:text-white',
	none: 'bg-gray-100 text-black',
	orange: 'bg-orange-500 hover:bg-orange-600 border-2 border-orange-500 hover:border-orange-600 text-white',
	orange_inverted:
		'bg-none border-2 text-black border-orange-500 hover:bg-orange-500 hover:text-white',
	pet_primary_green:
		'bg-green-600 hover:bg-green-600 border-2 border-green-600 text-white',
	primary:
		'bg-theme-main hover:bg-theme-highlight text-white focus:bg-secondary',
	secondary:
		'text-black border-2 border-black hover:bg-black hover:text-white',
	transparent: 'text-black',
	inverted_zenrelia_primary:
		'bg-none border-2 text-inverted_zenrelia_primary border-inverted_zenrelia_primary hover:bg-inverted_zenrelia_primary hover:text-white focus:bg-inverted_zenrelia_primary focus:text-white',
}

type Target = '_blank' | '_self' | '_parent' | '_top' | ''

interface ButtonProps {
	as?: 'a' | 'button'
	children?:
		| string
		| ReactElement<
				PropsWithChildren<{
					className?: string
					dangerouslySetInnerHTML?: {__html: string}
				}>
		  >
		| ReactElement<
				PropsWithChildren<{
					className?: string
					dangerouslySetInnerHTML?: {__html: string}
				}>
		  >[]

	className?: string
	disabled?: boolean
	href?: string
	icon?:
		| ReactElement<{className?: string}>
		| {class: string; icon: ReactElement<{className?: string}>}
		| null
	noIconAnimation?: boolean
	onClick?: (
		event:
			| React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
			| React.FormEvent<HTMLFormElement>
	) => void | Promise<void>

	padding?: string
	reversed?: boolean
	target?: Target
	title?: string
	type?: 'button' | 'submit' | 'reset'
	variant?: string
	routerNavigateToPath?: (path: string) => Promise<boolean>
	rel?: string
	locale?: string
}

export const Button = (props: ButtonProps): JSX.Element => {
	const {
		as: Tag = 'a',
		children,
		className = '',
		disabled = false,
		href,
		icon,
		noIconAnimation = false,
		onClick,
		padding = 'px-4 py-3',
		reversed = false,
		target,
		title,
		type = 'button',
		variant = 'primary',
	}: ButtonProps = props

	const [subTheme, setSubTheme] = useState<string>('')
	const {getSubTheme} = UseTheme()

	useEffect(() => {
		const subtheme = getSubTheme()
		setSubTheme(
			typeof subtheme === 'string' ? subtheme : subtheme.background
		)
	}, [getSubTheme])

	const dataProps = getDataAttributes(props as Record<string, unknown>)
	let iconPadding = ''
	let iconAnimClass = ''
	let iconToUse = icon
	if (icon && typeof icon === 'object' && 'icon' in icon) {
		iconAnimClass = noIconAnimation ? '' : `btn-${icon.class}`
		iconToUse = icon.icon
	}
	const hasIcon = iconToUse ?? null
	let newIcon

	const getClasses = (variantName: keyof typeof classes): string => {
		if (classes[variantName]) return classes[variantName]
		return `bg-${variantName} text-white brandtext-${variantName}`
	}

	let tagAttributes = {
		href,
	} as Pick<ButtonProps, 'href'> &
		Pick<Partial<ButtonProps>, 'target'> &
		Pick<Partial<ButtonProps>, 'type'> &
		Pick<Partial<ButtonProps>, 'disabled'>

	if (target) {
		tagAttributes.target = target
	}

	if (Tag === 'button') {
		tagAttributes = {disabled, type}
	}

	if (hasIcon && isValidElement(iconToUse)) {
		newIcon = cloneElement(
			iconToUse as ReactElement<{className?: string}>,
			{
				className: `${
					iconToUse.props.className
				} inline-block fill-current mx-3 shrink-0 overflow-visible ${
					props.reversed ? 'mr-5' : 'ml-5'
				}`,
				key: iconToUse.key,
			}
		)
		iconPadding = reversed ? 'pl-3' : 'pr-3'
	}

	// if data-btn-id is not set on each button, using the hyphenated button text as data-btn-id for tracking purpose
	if (
		children &&
		(dataProps['data-btn-id'] === undefined ||
			dataProps['data-btn-id'] === '')
	) {
		let dataBtnIdFromText: ReactNode = ''
		if (Array.isArray(children)) {
			let tempText = ''
			tempText = children
				.map((c) => {
					return !c.type ? c : c.props.children
				})
				.join('')
			dataBtnIdFromText = tempText.replace(',', '-')
		} else if (
			isValidElement(children) &&
			children.props.dangerouslySetInnerHTML
		) {
			// children is raw HTML, so strip out the tags
			dataBtnIdFromText =
				children.props.dangerouslySetInnerHTML.__html.replace(
					/(?<temp2><(?<temp1>[^>]+)>)/gi,
					''
				)
		} else if (isValidElement(children) && children.type) {
			// children is an element
			dataBtnIdFromText = children.props.children
		}

		if (typeof dataBtnIdFromText === 'string')
			dataProps['data-btn-id'] = dataBtnIdFromText.split(' ').join('-')
	}

	return (
		<Tag
			{...tagAttributes}
			aria-label={title}
			title={title}
			{...dataProps}
			className={`btn ${iconAnimClass} ${
				subTheme || getClasses(variant as keyof typeof classes)
			} inline-flex cursor-pointer items-center overflow-hidden rounded-sm text-center align-middle font-bold
        ${padding}
        ${iconPadding} ${className || ''} ${
			hasIcon ? 'justify-between' : 'justify-center'
		}
        ${disabled && Tag === 'a' ? 'disabled' : ''}`}
			onClick={onClick}
		>
			{hasIcon && reversed ? newIcon : null}
			{children}
			{hasIcon && !reversed ? newIcon : null}
		</Tag>
	)
}
