import Link from 'next/link'
import Image from 'next/future/image'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {TextButton} from '@/_new-code/products/flexible-web-toolkit/components/button/text-button'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import {pushToDataLayer} from '@/utils/analytics'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {SmallTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/small-text'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import type {RebatesTableRowContentItem} from '.'

const DEFAULT_LOGO_HEIGHT = 80
const DEFAULT_LOGO_WIDTH = 160

type RebatesRowContentItem = IContentItem<{
	rebate: Elements.LinkedItemsElement<RebatesTableRowContentItem>
	columnHeadings: Elements.LinkedItemsElement<SmallTextContentItem>
}>

export const RebatesRowBlock: Block<RebatesRowContentItem> = ({
	block: {
		elements: {
			columnHeadings,
			rebate: [rebate],
		},
	},
	...context
}) => {
	const {locale: pageLocale = ''} = useRouter()

	// eslint-disable-next-line react/jsx-no-useless-fragment -- TODO: remove when React types upgrade
	if (!rebate) return <></>

	return (
		<tr className="relative grid grid-cols-2 border-b border-light-grey !bg-white px-4 py-2 text-sm md:table-row">
			<td className="col-span-2 grid place-content-start border-r-0 px-2 py-2 text-left first-letter:uppercase md:table-cell md:px-4 md:pl-0">
				<span className="block w-full max-w-[160px] text-[#333] md:max-w-[130px]">
					{rebate.elements.brandImage.map(
						(
							{
								elements: {
									image: [image],
								},
								system: {id},
							},
							index
						) => {
							if (!image) return null

							return (
								<Image
									alt={image.description ?? undefined}
									className={index === 1 ? 'mt-2' : undefined}
									height={image.height || DEFAULT_LOGO_HEIGHT}
									key={id}
									loader={loadImageFromKontentAI}
									src={image.url}
									width={image.width || DEFAULT_LOGO_WIDTH}
								/>
							)
						}
					)}
				</span>
			</td>
			<td className="col-span-2 grid place-content-start border-r-0 px-2 py-2 text-left md:table-cell md:px-4">
				<span className="font-bold italic text-midnight-blue md:hidden">
					{columnHeadings[1]?.elements.content}
				</span>
				<span className="text-lg font-bold text-[#333]">
					{rebate.elements.offerCode}
				</span>
			</td>
			<td className="col-span-1 grid place-content-start border-r-0 px-2 py-2 text-left md:table-cell md:max-w-[240px] md:px-4">
				<span className="font-bold italic text-midnight-blue md:hidden">
					{columnHeadings[2]?.elements.content}
				</span>
				<RichTextRenderer
					as="span"
					className="text-[#333]"
					element={rebate.elements.offerDetails}
					{...context}
				/>
			</td>
			<td className="col-span-1 grid place-content-start border-r-0 px-2 py-2 text-left md:table-cell md:max-w-[145px] md:px-4">
				<span className="font-bold italic text-midnight-blue md:hidden">
					{columnHeadings[3]?.elements.content}
				</span>
				<RichTextRenderer
					as="span"
					className="text-[#333]"
					element={rebate.elements.rebateValue}
					{...context}
				/>
			</td>
			<td className="col-span-1 grid place-content-start border-r-0 px-2 py-2 text-left md:table-cell md:px-4">
				<span className="font-bold italic text-midnight-blue md:hidden">
					{columnHeadings[4]?.elements.content}
				</span>
				<span className="text-[#333]">
					{rebate.elements.validPurchaseDates}
				</span>
			</td>
			<td className="col-span-1 grid place-content-start border-r-0 px-2 py-2 text-left md:table-cell md:px-4">
				<span className="font-bold italic text-midnight-blue md:hidden">
					{columnHeadings[4]?.elements.content}
				</span>
				<span className="text-[#333]">
					{rebate.elements.submissionDeadline}
				</span>
			</td>
			<td className="col-span-2 grid border-r-0 px-2 py-2 text-center md:table-cell md:max-w-[150px] md:px-0">
				{rebate.elements.claimRebateCta.map((btn) => (
					<Link
						href={addLocalePrefix(btn.elements.url, pageLocale)}
						key={btn.system.id}
					>
						<Button
							href={btn.elements.url}
							onClick={() => {
								pushToDataLayer({
									event: 'cta_click',
									cta_name: btn.elements.text,
									cta_category: 'CTA_Button',
								})
							}}
							target={
								btn.elements.openLinkInNewTab[0]?.codename ===
								'yes'
									? '_blank'
									: '_self'
							}
							variant={
								btn.elements.variant[0]?.codename ||
								'pet_primary_green'
							}
						>
							{btn.elements.text}
						</Button>
					</Link>
				))}
				{rebate.elements.offerInfoText ? (
					<p className="my-1 text-center text-xs">
						{rebate.elements.offerInfoText}
					</p>
				) : null}
				{rebate.elements.safetyLinks[0] ? (
					<ul className="flex flex-col justify-center space-y-1">
						{rebate.elements.safetyLinks.map((link) => (
							<TextButton
								as="a"
								className="text-center not-italic text-green-400 hover:text-green-600"
								href={addLocalePrefix(
									link.elements.url,
									pageLocale
								)}
								key={link.system.id}
								target={
									link.elements.openInNewTab[0]?.codename ===
									'yes'
										? '_blank'
										: '_self'
								}
							>
								{link.elements.text}
							</TextButton>
						))}
					</ul>
				) : null}
			</td>
		</tr>
	)
}
